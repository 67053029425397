import Rails from '@rails/ujs';
console.log("IM PORTED")
function dragstart_handler(e) {

    e.dataTransfer.setData("text/plain", e.target.id);
    e.target.style.cursor = 'grabbing';

    window.currentDragged = e.target
}

function drop_handler(e) {
    // console.log(currentDragged)
    e.preventDefault()
    e.target.style.background = "";

    //move the meal to the new date
    currentDragged.parentNode.removeChild( currentDragged );

    e.target.appendChild( currentDragged);
    e.target.style.cursor = 'default';
    e.target.classList.add("animate_animated", "animate__heartBeat")

    e.preventDefault()

    update(currentDragged)
}

function update(cooking_el) {
         // Save the current scroll height so that Turbolinks can find it
    window.lastPositionY = window.scrollY

        // Find the new date for cooking_params somewhere in the cell
    let new_date = cooking_el.closest("td").dataset.dropzone
    let cooking_date = `cooking[cooked_at]=${new_date}`
    // console.log("found a date", new_date)

    // Find the url to PATCH to
    let patch_url = cooking_el.getAttribute('href');  // /cookings/27

    // Send to CookingsController
    Rails.ajax({
        url: patch_url,
        type: "PATCH",
        data: cooking_date,
        success: function(data) {},
        error: function(data) {}
    })
}

///////
//Preparing for drag and drop: register, adding eventlisteners and data-attrs
////////

function initEachDropzone(dropzone) {
    dropzone.addEventListener("dragover", function(event) {
        event.preventDefault(); // to allow drop
        event.target.style.background = "floralwhite";
    }, false);
    dropzone.addEventListener("dragleave", function(event) {
        event.preventDefault();
        event.currentTarget.style.background = "";
    }, false);
    dropzone.addEventListener("drop", drop_handler)

}

    //A bitt (Dutch: beting) is the thing on a tow boat to secure a cable
function initEachBitt(bitt) {
    bitt.addEventListener("dragstart", dragstart_handler);
}

function registerDraggablesAndDropzones() {
    // console.log("Registering......")
    const bitts = [...document.querySelectorAll("[draggable]")]
    bitts.forEach(initEachBitt)

    const dropzones = [...document.querySelectorAll("[data-dropzone]")].forEach(initEachDropzone);
        // console.log(drops.length) # //To confirm:with the 2 week calendar view, it returns 14 cells
}

document.addEventListener('turbolinks:load', registerDraggablesAndDropzones);
// document.addEventListener("turbolinks:load", function (e) {
//     let lastPosition = typeof lastPositionY != "undefined" ? lastPositionY : 0
//     window.scrollTo(0, lastPosition)
// })

